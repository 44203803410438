import React, { Component } from 'react'

export default class DeleteConfirm extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        const {bookings,id} = this.props
        return (
            <div style={{backgroundColor:"#f2f2f2",top:0}} className='delete-reservation w-100 h-100  position-fixed text-center d-flex justify-content-center align-items-center'>
                <div style={{maxWidth:700}} className="card text-dark bg-white d-flex justify-content-center px-40 py-20 w-100">
                    <h3 className='title-delete' style={{fontSize:"26px"}}>予約削除</h3>
                    <p>削除すると以下の情報が全て失われます</p>
                    <div style={{backgroundColor:"#f2f2f2", overflowY:"scroll", maxHeight:"300px"}} className="card-body d-flex flex-column align-items-start">
                        <div>
                            <div className='d-flex flex-row flex-wrap' style={{alignItems:"start"}}>
                                <h5 style={{fontSize:"16px",minWidth:80,textAlign:"start"}}>・時間帯：</h5>
                                <p style={{fontSize:"16px",wordWrap:"break-word",wordBreak:"break-all",textAlign:"left"}} className="card-time">{`${bookings[id].startTime}-${bookings[id].endTime}`}</p>

                            </div>
                            <div className='d-flex flex-row flex-wrap' style={{alignItems:"start"}}>
                                <h5 style={{fontSize:"16px",minWidth:70,textAlign:"start"}}>・名前：</h5>
                                <p style={{fontSize:"16px",wordWrap:"break-word",wordBreak:"break-all",textAlign:"left"}} className="card-name">{bookings[id].name}</p>
                            </div>
                            <div className='d-flex flex-row flex-wrap' style={{alignItems:"start"}}>
                                <h5 style={{fontSize:"16px",minWidth:70,textAlign:"start"}}>・LINEの登録名：</h5>
                                <p style={{fontSize:"16px",wordWrap:"break-word",wordBreak:"break-all",textAlign:"left"}} className="card-name">{bookings[id].line_user_name}</p>
                            </div>
                            <div className='d-flex flex-row flex-wrap' style={{alignItems:"start"}}>
                                <h5 style={{fontSize:"16px",minWidth:85,textAlign:"start"}}>・携帯番号：</h5>
                                <p style={{fontSize:"16px",wordWrap:"break-word",wordBreak:"break-all",textAlign:"left"}} className="card-phone">{bookings[id].phoneNumber}</p>
                            </div>
                            <div className='d-flex flex-row flex-wrap' style={{alignItems:"start"}}>
                                <h5 style={{fontSize:"16px",minWidth:80,textAlign:"start"}}>・コース：</h5>
                                <p style={{fontSize:"16px", whiteSpace: "break-spaces", wordBreak: "break-word",textAlign:"left"}} className="card-option">{bookings[id].option}</p>
                            </div>
                        </div>


                    </div>

                    <div className='d-flex justify-content-between mt-20'>
                        <button onClick={this.props.onCancel} style={{border:"1px solid",minWidth:150,fontSize:"22px"}} className='btn btn-light font-weight-bold'>キャンセル</button>
                        <button onClick={this.props.onConfirm} style={{minWidth:150,fontSize:"22px"}} className='btn btn-success font-weight-bold'>削除</button>

                    </div>
                </div>

            </div>
        )
    }
}
