import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

export default class InformationConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCompleted: false
    };
  }
  formatPrice = (price) => {
    if (typeof price === 'number') {
      price = price.toString();
    }

    if (typeof price !== 'string' || price.trim() === '') {
      return '0';
    }

    let [integerPart = '', decimalPart = ''] = price.split('.');

    let formattedIntegerPart = parseInt(integerPart).toLocaleString();

    return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : `${formattedIntegerPart}`;
  }
  render() {
    const { optionDetail, bookingTime, userInfo } = this.props;
    return (
      <div className='w-100 text-dark user-confirm-container d-flex justify-content-start'>
        <div style={{ maxWidth: 500,width:"100%" }}>
          <div className='booking-information'>

            <h1 style={{ backgroundColor: "#f2f2f2", fontSize: "16px" }} className='title-form px-10 my-30'>
              ご予約内容
            </h1>

            <div className='d-flex flex-column w-100 mb-20 start'>
              <p style={{ fontSize: "16px" }} className='font-weight-bold'>来店日時</p>
              <p style={{ fontSize: "16px" }} className=''>{bookingTime.appointmentDate}</p>
            </div>


            <div className='d-flex flex-column w-100 mb-20 option-time'>
              <p style={{ fontSize: "16px" }} className='font-weight-bold'>所要時間合計（目安）</p>
              <p style={{ fontSize: "16px" }} className=''>{optionDetail.totalTime}</p>
            </div>
            <div className='d-flex flex-column w-100 mb-20 option-name'>
              <p style={{ fontSize: "16px" }} className='font-weight-bold'>コース</p>
              <p style={{ 
                fontSize: "16px", 
                whiteSpace: "break-spaces",
                wordBreak: "break-word" 
                }} className=''>{optionDetail.option}</p>
            </div>
            <div className='d-flex flex-column w-100 mb-20 option-price'>
              <p style={{ fontSize: "16px" }} className='font-weight-bold'>合計金額</p>
              <p style={{ fontSize: "16px" }} className=''>{this.formatPrice(optionDetail.totalPrice)}円</p>
            </div>

          </div>

          <div className='user-information'>

            <h2 style={{ backgroundColor: "#f2f2f2", fontSize: "16px" }} className='title-form px-10 my-30'>
              お客様情報
            </h2>

            <div className='d-flex flex-column w-100 mb-20 user-name'>
              <p style={{ fontSize: "16px" }} className='font-weight-bold'>ご予約者氏名</p>
              <p style={{ fontSize: "16px" }} className=''>{userInfo.name}</p>
            </div>


            <div className='d-flex flex-column w-100 mb-20 user-phone'>
              <p style={{ fontSize: "16px" }} className='font-weight-bold'>電話番号</p>
              <p style={{ fontSize: "16px" }} className=''>{userInfo.phone}</p>
            </div>
            <div className='d-flex flex-column w-100 mb-20 user-age'>
              <p style={{ fontSize: "16px" }} className='font-weight-bold'>生年</p>
              <p style={{ fontSize: "16px" }} className=''>{moment(userInfo.birthYear).format('YYYY')} 年</p>
            </div>
            <div className='d-flex flex-column w-100 mb-20 user-gender'>
              <p style={{ fontSize: "16px" }} className='font-weight-bold'>性別</p>
              <p style={{ fontSize: "16px" }} className=''>{userInfo.gender}</p>
            </div>
            <div className='d-flex flex-column w-100 mb-20 user-compose'>
              <p style={{ fontSize: "16px" }} className='font-weight-bold'>ご来店の目的・ご要望・ご質問</p>
              <p style={{ fontSize: "16px" }} className=''>{userInfo.purpose}</p>
            </div>


          </div>


          <div className='d-flex justify-content-center w-100 mb-20 btn-user-confirm'>
            <button onClick={this.props.onHandleConfirm} className="btn btn-success px-20 font-weight-bold" style={{  fontSize:"22px" }} >
              予約を確定する
            </button>
          </div>

          <div className='d-flex justify-content-start w-50'>
            <button onClick={this.props.onHandleBack} className="btn btn-light px-20 mb-20  font-weight-bold" style={{ border: "1px solid", fontSize:"22px" }}>
              戻る
            </button>
          </div>

        </div>

      </div>
    );
  }
}
